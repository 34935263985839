<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="bankIcons"
          :search="search"
          title="Bank Icon"
          subtitle="Halaman untuk menambahkan icon Bank Soal"
          add-btn="Tambah Data"
          :is-loading="isLoadingData"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @show-icon="showImage"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid || bankIcon.icon === null"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Bank Icon': 'Edit Bank Icon'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-combobox
                v-model="bankIcon.tags"
                :items="tagList"
                :search-input.sync="search"
                return-object
                item-text="name"
                item-value="uuid"
                hint="Maksimal 5 tag"
                label="Tambah Tag"
                hide-selected
                multiple
                outlined
                dense
                clearable
                persistent-hint
                small-chips
                @change="addTag"
              >
                <template #no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Tidak ada hasil yang cocok untuk "<strong>{{ search }}</strong>". Tekan <kbd>enter</kbd> untuk membuat tag baru
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <FileInput
                v-model="initialFile"
                :value="bankIcon.icon"
                :prependicon="icons.mdiImageMultipleOutline"
                label="Icon"
                outlined
                dense
                @input="getFile"
              ></FileInput>
            </v-col>
          </v-row>
        </div>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Bank Icon"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>

    <ImageOverlay
      :visible="imageOverlay"
      :width="width"
      @close="imageOverlay = false"
    >
      <template v-slot:body>
        <v-card>
          <v-btn
            class="close-image"
            color="white"
            icon
            @click="closeImage"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-img
            :src="bankIcon.icon"
            transition="scroll-y-transition"
            min-width="100"
            max-width="700"
          ></v-img>
        </v-card>
      </template>
    </ImageOverlay>
  </div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog.vue'
import DataTablePagination from '../components/DataTablePagination.vue'
import FileInput from '../components/FileInput.vue'
import ImageOverlay from '../components/ImageOverlay.vue'
import MainCard from '../components/MainCard.vue'
import ModalDialog from '../components/ModalDialog.vue'
import SkeletonLoaderTable from '../components/SkeletonLoaderTable.vue'
// eslint-disable-next-line object-curly-newline
import { mdiClose, mdiImageMultipleOutline } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  name: 'BankIcon',
  components: {
    DataTablePagination,
    ConfirmDialog,
    ModalDialog,
    FileInput,
    ImageOverlay,
    SkeletonLoaderTable,
    MainCard,
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.bankIcon.icon === 'string') {
          return new File([this.bankIcon.icon], this.bankIcon.icon, { type: 'text/plain' })
        }

        return this.bankIcon.icon
      },
      set() {
        return this.bankIcon.icon
      },
    },
  },
  setup(props, context) {
    const page = ref(1)
    const search = ref('')
    const dialog = ref(null)
    const width = ref(600)
    const totalPages = ref(0)
    const totalItems = ref(0)
    const modalDialog = ref(false)
    const confirmDialog = ref(false)
    const loading = ref(true)
    const isLoadingTable = ref(true)
    const isLoadingData = ref(false)
    const isLoadingButton = ref(false)
    const overlay = ref(false)
    const imageOverlay = ref(false)
    const icons = ref({
      mdiClose,
      mdiImageMultipleOutline,
    })
    const formValid = ref(false)
    const formData = ref(null)

    const headers = ref([
      { text: 'No', value: 'index' },
      { text: 'Tag', value: 'tagName' },
      { text: 'Icon', value: 'icon' },
      { text: 'Actions', value: 'actions' },
    ])
    const filterQuery = ref({
      search: '',
    })
    const tagList = ref([])
    const bankIcons = ref([])
    const bankIcon = ref({
      uuid: '',
      icon: null,
      name: [],
      tags: [],
      tag: '',
    })
    const tagLists = ref({
      uuid: '',
      name: '',
    })

    const showSnackbar = snackbarData => {
      context.root.$store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const resetForm = () => {
      bankIcon.value.tags = ''
      bankIcon.value.icon = null
    }

    const showFormAdd = () => {
      dialog.value = 'add'
      resetForm()
      modalDialog.value = true
    }

    const showImage = async uuid => {
      await context.root.$services.ApiServices.get('bankicon', uuid).then(
        ({ data }) => {
          bankIcon.value = data.data
          bankIcon.value.icon = data.data.icon
        },
        err => console.error(err),
      )
      imageOverlay.value = true
    }

    const closeImage = async () => {
      overlay.value = false
      imageOverlay.value = false
    }

    const listTag = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('tag', {}).then(
        ({ data }) => {
          tagList.value = data.data
        },
        err => console.error(err),
      )
      isLoadingData.value = false
    }

    const listBankIcon = async (params = {}) => {
      isLoadingData.value = true
      await context.root.$services.ApiServices.list('bankicon', { ...params, page: page.value }).then(
        ({ data }) => {
          bankIcons.value = data.data.map((bankIcons, index) => ({
            ...bankIcons,
            index: index + 1,
          }))
          totalItems.value = data.meta.total
          totalPages.value = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      isLoadingTable.value = false
      isLoadingData.value = false
    }

    const addTag = async () => {
      const lastIndex = bankIcon.value.tags.length - 1
      if (typeof bankIcon.value.tags[lastIndex] === 'object') {
        return
      }
      await context.root.$services.ApiServices.add('tag', { name: bankIcon.value.tags[lastIndex] }).then(
        res => {
          // const dataNew = {
          //   uuid: res.data.data.uuid,
          //   name: res.data.data.name,
          // }
          bankIcon.value.tags.splice(lastIndex, 1)
          bankIcon.value.tags.push(res.data.data)
        },
        err => {
          console.error(err)
        },
      )
    }

    const add = async () => {
      if (bankIcon.value.tags.length < 1 || bankIcon.value.icon === null) {
        showSnackbar({
          text: 'Name tag/icon harus diisi',
          color: 'error',
        })

        return
      }
      isLoadingButton.value = true
      formData.value = new FormData()
      formData.value.append('icon', bankIcon.value.icon)
      bankIcon.value.tags.forEach(itemTags => {
        formData.value.append('tag_uuid[]', itemTags.uuid)
      })
      await context.root.$services.ApiServices.add('bankiconpost', formData.value).then(
        res => {
          resetForm()
          showSnackbar({
            text: 'Add Bank Icon Has Successfully',
            color: 'success',
          })
          resetForm()
          listBankIcon(filterQuery.value)
        },
        err => {
          resetForm()
          isLoadingButton.value = false
          console.error(err)
        },
      )
      await listBankIcon(filterQuery.value)
      await listTag()
      isLoadingButton.value = false
      resetForm()
      modalDialog.value = false
    }

    const showFormEdit = async uuid => {
      dialog.value = 'edit'
      bankIcon.value.uuid = uuid
      await context.root.$services.ApiServices.get('bankicon', uuid).then(
        ({ data }) => {
          bankIcon.value.icon = data.data.icon
          bankIcon.value.tags = data.data.tags

          modalDialog.value = true
        },
        err => console.error(err),
      )
    }

    const update = async () => {
      if (bankIcon.value.tags.length < 1 || bankIcon.value.icon === null) {
        showSnackbar({
          text: 'Name tag/icon harus diisi',
          color: 'error',
        })

        return
      }
      isLoadingButton.value = true
      formData.value = new FormData()
      const oldPhoto = bankIcon.value.icon

      if (typeof oldPhoto === 'string') {
        formData.value.delete('icon')
      } else {
        formData.value.append('icon', bankIcon.value.icon)
      }
      bankIcon.value.tags.forEach(itemTags => {
        formData.value.append('tag_name[]', itemTags.name)
        formData.value.append('tag_uuid[]', itemTags.uuid)
        // formData.value.append('tag_name[]', itemTags.name)
      })
      await context.root.$services.ApiServices.update('bankicon', formData.value, bankIcon.value.uuid).then(
        res => {
          modalDialog.value = false
          showSnackbar({
            text: 'Update Bank Icon Has Successfully',
            color: 'success',
          })
          resetForm()
        },
        err => console.error(err),
      )
      await listBankIcon(filterQuery.value)
      await listTag()
      isLoadingButton.value = false

      modalDialog.value = false
      dialog.value = 'add'
    }

    const confirmDestroy = uuid => {
      confirmDialog.value = true
      bankIcon.value.uuid = uuid
    }

    const destroy = async () => {
      isLoadingButton.value = true
      await context.root.$services.ApiServices.destroy('bankicon', bankIcon.value.uuid).then(
        res => {
          listBankIcon()
          showSnackbar({
            text: 'Delete Bank Icon Has Successfully',
            color: 'error',
          })
        },
        err => console.error(err),
      )
      isLoadingButton.value = false
      confirmDialog.value = false
    }

    const handlerButton = () => {
      if (dialog.value === 'add') add()
      else update()
    }

    const getFile = data => {
      bankIcon.value.icon = data
    }

    const searchHandler = async subject => {
      filterQuery.value.search = subject
      await listBankIcon(filterQuery.value)
    }

    const paginationHandler = pageNumber => {
      page.value = pageNumber
    }

    watch(page, listBankIcon, searchHandler, paginationHandler)
    watch(bankIcon.value, () => {
      const valid = []
      const requiredField = ['tags']
      Object.entries(bankIcon.value).forEach(([key, item]) => {
        if (requiredField.includes(key)) valid.push(!!(item && item.length))
      })
      formValid.value = !valid.includes(false)
    })

    onMounted(() => {
      listTag()
      listBankIcon()
    })

    return {
      formValid,
      add,
      showFormEdit,
      update,
      resetForm,
      getFile,
      bankIcons,
      headers,
      tagList,
      icons,
      bankIcon,
      page,
      search,
      dialog,
      width,
      totalPages,
      totalItems,
      confirmDialog,
      isLoadingTable,
      destroy,
      confirmDestroy,
      isLoadingData,
      isLoadingButton,
      showFormAdd,
      modalDialog,
      addTag,
      tagLists,
      formData,
      loading,
      listTag,
      listBankIcon,
      searchHandler,
      paginationHandler,
      handlerButton,
      showSnackbar,
      showImage,
      closeImage,
      imageOverlay,
    }
  },
}
</script>

<style>
td p {
  margin: 0 !important;
}
.v-text-field__details {
  padding: 0px !important;
  margin: 8px 0 !important;
}

.v-list-item {
  min-height: 30px !important;
}

td > div > .v-list {
  background-color: transparent !important;
}
</style>
